import { ref } from 'vue';
import api from '@/services/useAxios';

export default function useUser() {
    const user = ref(null);

    const getUser = async (userId) => {
        const path = `/users/${userId}`;
        const res = await api.get(path);

        if (res.status === 200) {
            user.value = res.data.user;
        }
    };

    const updatePassword = async (userId, password, newPassword) => {
        const body = { password, newPassword };
        const path = `/users/${userId}/password`;

        const res = await api.put(path, body);
        if (res.status === 201) {
            return true;
        }

        throw Error(res.data.errors);
    };

    const updateUser = async (userId, data) => {
        // eslint-disable-next-line object-curly-newline
        const { name, surnames, email, status, profile } = data;
        const body = {
            name,
            surnames,
            email,
            status,
            profile,
        };
        const path = `/users/${userId}`;
        const res = await api.put(path, body);
        if (res.status === 201) {
            return true;
        }

        throw Error(res.data.errors);
    };

    const createUser = async (data) => {
        // eslint-disable-next-line object-curly-newline
        const { alias, name, surnames, email, status, profile, password } = data;
        const body = {
            alias,
            name,
            surnames,
            email,
            status,
            profile,
            password,
        };

        const path = '/users';

        const res = await api.post(path, body);
        if (res.status === 201) {
            return true;
        }

        throw Error(res);
    };

    return {
        user,
        getUser,
        updatePassword,
        updateUser,
        createUser,
    };
}
