import { ref } from 'vue';
import api from '@/services/useAxios';

export default function useUserPhoto() {
    const tempPhoto = ref('');

    const uploadTempPhoto = async (userId, imgFile) => {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const path = `/users/photo/temp/user/${userId}`;
        const formData = new FormData();
        formData.append('file', imgFile);
        const res = await api.post(path, formData, { headers });

        if (res.status === 200) {
            tempPhoto.value = res.data;
            return true;
        }
        throw Error('FAILED UPLOADING PHOTO');
    };

    const confirmTempPhoto = async (userId, fileId) => {
        const path = `/users/photo/user/${userId}`;
        const body = { id: fileId };
        const res = await api.put(path, body);

        if (res.status === 201) {
            tempPhoto.value = '';
            return true;
        }
        throw Error('ERROR PUT PHOTO');
    };

    return { tempPhoto, uploadTempPhoto, confirmTempPhoto };
}
