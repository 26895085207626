<template>
    <WindowLayout :title="title" v-if="ready">
        <div class="row">
            <div class="col-md-6 col-lg-4" v-if="state.id">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>Configuracion de Usuario</h5>
                    </div>
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-12 d-flex justify-content-center mb-1">
                                <UserProfilePhotoEditor :userId="state.id" />
                            </div>
                            <div class="alias col-12 d-flex justify-content-center">
                                @<em>{{ state.alias }}</em>
                            </div>
                        </div>
                        <hr class="mb-4" />
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <label class="form-label mb-1">Contraseña Actual</label>
                                    <input
                                        class="form-control"
                                        type="password"
                                        v-model="state.currentPassword"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label mb-1">Cambiar contraseña</label>
                                    <input
                                        class="form-control"
                                        type="password"
                                        v-model="state.password"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label mb-1">
                                        Confirmar nueva contraseña
                                    </label>
                                    <input
                                        class="form-control"
                                        type="password"
                                        v-model="state.password2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-12 text-end">
                                <div class="btn btn-primary ms-auto" @click="handleUpdatePassword">
                                    <i class="far fa-save"></i>
                                    Guardar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-8" v-if="state.id">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>Informacion del Usuario</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Nombre</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.name"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Apellidos</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.surnames"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Email</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.email"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Perfil</label>
                                    <div class="col-sm-9">
                                        <treeselect
                                            v-model="state.profile"
                                            :options="profiles"
                                        ></treeselect>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Estado</label>
                                    <div class="col-sm-9">
                                        <SelectInputSimpleState
                                            v-model="state.status"
                                        ></SelectInputSimpleState>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-12 text-end">
                                <div class="btn btn-primary ms-auto" @click="handleUpdate">
                                    <i class="far fa-save"></i>
                                    Guardar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="!state.id">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>Crear Usuario</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Email</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.email"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Usuario</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.alias"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Contraseña</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="password"
                                            v-model="state.password"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label"
                                        >Confirmar contraseña</label
                                    >
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="password"
                                            v-model="state.password2"
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Nombre</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.name"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Apellidos</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.surnames"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Perfil</label>
                                    <div class="col-sm-9">
                                        <treeselect
                                            v-model="state.profile"
                                            :options="profiles"
                                        ></treeselect>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Estado</label>
                                    <div class="col-sm-9">
                                        <SelectInputSimpleState
                                            v-model="state.status"
                                        ></SelectInputSimpleState>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-12 text-end">
                                <div class="btn btn-primary ms-auto" @click="handleCreate">
                                    <i class="far fa-save"></i>
                                    Guardar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { defineComponent, onBeforeMount, onMounted, reactive, ref, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line object-curly-newline
import { required, email, minLength, sameAs } from '@vuelidate/validators';
import { useSnackbar } from 'vue3-snackbar';
import axios from '@/services/useAxios';
import Treeselect from 'vue3-treeselect';
import WindowLayout from '@/layouts/Window.layout.vue';
import SelectInputSimpleState from '@/components/form/SelectInputSimpleState.vue';
import useUser from './services/useUser';
import UserProfilePhotoEditor from './components/UserProfilePhotoEditor.vue';

export default defineComponent({
    name: 'UserForm',
    components: {
        WindowLayout,
        SelectInputSimpleState,
        Treeselect,
        UserProfilePhotoEditor,
    },
    props: {
        title: { type: String, default: 'Usuario' },
        headerTitle: { type: String, default: 'Crear Usuario' },
        urlRead: { type: String, required: true },
        urlCreate: { type: String, required: false },
        urlUpdate: { type: String, required: false },
        item: Object,
    },
    setup(props) {
        const ready = ref(false);
        const snackbar = useSnackbar();
        const profiles = ref([]);
        // eslint-disable-next-line object-curly-newline
        const { user, getUser, createUser, updatePassword, updateUser } = useUser();
        const state = reactive({
            id: '',
            alias: '',
            name: '',
            surnames: '',
            email: '',
            currentPassword: '',
            password: '',
            password2: '',
            photo: '',
            lastLogin: '',
            profile: null,
            status: '1',
        });

        const rules = computed(() => {
            if (props.item) {
                return {
                    email: { required, email },
                    name: { required },
                    surnames: { required },
                    profile: { required },
                    status: { required },
                };
            }
            return {
                alias: { required },
                email: { required, email },
                password: { required, minLength: minLength(8) },
                password2: { required, sameAs: sameAs(state.password) },
                name: { required },
                surnames: { required },
                profile: { required },
                status: { required },
            };
        });

        const rulesUpdatePassword = computed(() => ({
            currentPassword: { required },
            password: { required, minLength: minLength(8) },
            password2: { required, sameAs: sameAs(state.password) },
        }));
        const $v = useVuelidate(rules, state);
        const $v2 = useVuelidate(rulesUpdatePassword, state);

        onBeforeMount(async () => {
            if (props.item) {
                await getUser(props.item.id);
                state.id = user.value.id;
                state.alias = user.value.alias;
                state.name = user.value.name;
                state.surnames = user.value.surnames;
                state.email = user.value.email;
                state.photo = user.value.photo;
                state.profile = user.value.profile.id;
                state.status = user.value.status.toString();
            }
            ready.value = true;
        });

        onMounted(async () => {
            const { data } = await axios.get('/users/profile/select');
            profiles.value = data;
        });

        const handleUpdate = async () => {
            await $v.value.$validate();
            if (!$v.value.$error) {
                await updateUser(props.item.id, {
                    name: state.name,
                    surnames: state.surnames,
                    email: state.email,
                    profile: state.profile,
                    status: Number(state.status),
                })
                    .then(() => {
                        snackbar.add({
                            type: 'success',
                            text: 'Item ha sido actualizado',
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        error.data.errors.map((e) => {
                            snackbar.add({
                                type: 'error',
                                text: e.msg,
                            });
                            return true;
                        });
                    });
            } else {
                console.log($v.value.$errors);
            }
        };

        const handleCreate = async () => {
            await $v.value.$validate();
            if (!$v.value.$error) {
                await createUser({
                    alias: state.alias,
                    name: state.name,
                    surnames: state.surnames,
                    email: state.email,
                    password: state.password,
                    profile: state.profile,
                    status: Number(state.status),
                })
                    .then(async () => {
                        snackbar.add({
                            type: 'success',
                            text: 'Item ha sido agregado',
                        });
                        state.id = '';
                        state.alias = '';
                        state.name = '';
                        state.surnames = '';
                        state.email = '';
                        state.password = '';
                        state.password2 = '';
                        state.photo = '';
                        state.lastLogin = '';
                        state.profile = null;
                        state.status = '1';
                    })
                    .catch((error) => {
                        console.log(error);
                        error.data.errors.map((e) => {
                            snackbar.add({
                                type: 'error',
                                text: e.msg,
                            });
                            return true;
                        });
                    });
            } else {
                console.log($v.value.$errors);
            }
        };

        const handleUpdatePassword = async () => {
            await $v2.value.$validate();
            if (!$v2.value.$error) {
                await updatePassword(props.item.id, state.currentPassword, state.password).then(
                    () => {
                        state.currentPassword = '';
                        state.password = '';
                        state.password2 = '';
                        // eslint-disable-next-line comma-dangle
                    }
                );
            } else {
                console.log($v2.value.$errors);
            }
        };

        return {
            state,
            handleUpdate,
            profiles,
            handleCreate,
            handleUpdatePassword,
            ready,
        };
    },
});
</script>

<style scoped>
.alias {
    font-size: 1.15rem;
}
</style>
