<template>
    <div class="profile-picture-container">
        <div
            class="profile-picture overlay-container"
            :style="{ backgroundImage: 'url(' + photoUrl + ')' }"
        >
            <div class="overlay rounded-circle cursor-pointer">
                <div
                    class="d-flex justify-content-center align-items-center h-100 w-100 color-white"
                >
                    <div>
                        <input
                            type="file"
                            id="uploadCreadorPhoto"
                            hidden
                            @change="handleUploadPhoto"
                        />
                        <label for="uploadCreadorPhoto" class="cursor-pointer">
                            <i class="far fa-lg fa-upload text-light"></i>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-between" v-if="tempPhoto">
            <div class="btn btn-sm" @click="handleCancelTempFile()">
                <i class="far fa-lg fa-trash-alt"></i>
            </div>
            <div class="btn btn-sm" @click="handleConfirmPhoto()">
                <i class="far fa-lg fa-save"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import useUserPhoto from '../services/useUserPhoto';

export default defineComponent({
    name: 'UserProfilePhotoEditor',
    props: { userId: String },
    setup(props) {
        const photoPath = `${process.env.VUE_APP_MAIN_SERVICE}/users/photo/user`;
        const tempPhotoPath = `${process.env.VUE_APP_MAIN_SERVICE}/users/photo/temp`;

        const { tempPhoto, uploadTempPhoto, confirmTempPhoto } = useUserPhoto();

        const handleUploadPhoto = async (e) => {
            const files = e.target.files || e.dataTransfer.files;
            await uploadTempPhoto(props.userId, files[0]);
        };

        const handleCancelTempFile = () => {
            tempPhoto.value = '';
        };

        const handleConfirmPhoto = async () => {
            await confirmTempPhoto(props.userId, tempPhoto.value);
        };

        const photoUrl = computed(() => {
            if (tempPhoto.value) {
                return `${tempPhotoPath}/${tempPhoto.value}?date=${Date.now()}`;
            }
            return `${photoPath}/${props.userId}?date=${Date.now()}`;
        });

        return {
            tempPhoto,
            handleUploadPhoto,
            handleCancelTempFile,
            handleConfirmPhoto,
            photoUrl,
        };
    },
});
</script>

<style scoped>
.profile-picture {
    width: 10em;
    height: 10em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border: 1px solid var(--border-shadow);
}

.overlay {
    position: relative;
    /* Sit on top of the page content */
    display: none;
    /* Hidden by default */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    /* Black background with opacity */
    z-index: 2;
    /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer;
    /* Add a pointer on hover */
    transition: background-color 0.15s ease-in-out;
}

.overlay:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.overlay-container:hover .overlay {
    display: inherit;
}
</style>
